<template>
<div class="bestellen card">
  <div class="card-content">
    <div class="row">
      <div class="col s12">
        <span class="card-title">Kalender bestellen</span>
        <p>
          Bestellen Sie schon jetzt den Adventskalender (5€ netto/Stück) - Die bestellten Kalender
          werden durch Mitglieder des Lions-Club an Sie ausgeliefert.
        </p>
      </div>
      <div class="col s12">
        <div class="row">
          <div class="input-field col s12">
            <input id="kalenderAnzahl" type="number" min="0" placeholder="Anzahl"
                   :readonly="keineKalenderBestellen" v-model.number="kalenderCount">
            <label for="kalenderAnzahl" class="active">Anzahl</label>
          </div>
          <div class="input-field col s12">
            <p>
              <label>
                <input type="checkbox" v-model="keineKalenderBestellen"
                       @change="onKeineKalenderBestellenChange"/>
                <span>Ich möchte in diesem Jahr keine Kalender bestellen</span>
              </label>
            </p>
          </div>
          <div class="col s12">
            <button type="button" class="btn blue darken-1" @click="placeOrder"
                    :class="{disabled: !canOrder}"
                    style="width: 100%">Abschicken</button>
          </div>
        </div>
      </div>
      <div class="col s12">
        <p>Sie erhalten unter der von Ihnen angegebenen E-Mail Adresse eine Kopie Ihrer Antworten,
        sowie eine Bestätigung Ihres Sponsoring und Ihrer Kalenderbestellung.</p>
      </div>
    </div>
  </div>
  <div v-if="loading" class="loading-screen">
    <div class="preloader-wrapper big active">
      <div class="spinner-layer spinner-blue-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="gap-patch">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import M from 'materialize-css';
import axios from 'axios';

export default {
  name: 'Bestellen',
  data() {
    return {
      kontonummer: '',
      kalenderCount: undefined,
      keineKalenderBestellen: false,
      loading: false,
    };
  },
  mounted() {
    this.kontonummer = this.$route.query.konto;
  },
  methods: {
    async placeOrder() {
      if (this.keineKalenderBestellen || !this.kalenderCount) {
        this.$store.dispatch('updateKalenderBestellung', 0);
      } else {
        this.$store.dispatch('updateKalenderBestellung', this.kalenderCount);
      }

      this.loading = true;
      const url = `${process.env.VUE_APP_API_URL}/data/addSponsoring?sponsorenId=${this.kontonummer}`;
      const { payload } = this.$store.getters;

      console.log(payload);
      await axios.post(url, payload);

      this.loading = false;
      await this.$router.push('/end');
    },
    onKeineKalenderBestellenChange() {
      if (this.keineKalenderBestellen) {
        this.kalenderCount = 0;
        M.updateTextFields();
      }
    },
  },
  computed: {
    canOrder() {
      return this.keineKalenderBestellen || this.kalenderCount;
    },
  },
};
</script>

<style scoped>
.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(236, 231, 231, 0.822);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
</style>
